.sort-list.cdk-drop-list-dragging .sort-item {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.sort-item.cdk-drag-placeholder {
  visibility: hidden;
}

.sort-item {
  position: relative;

  .drag-handle {
    position: absolute;
    left: $spacer-2;
    top: $spacer-5;
    transition: opacity 0.2s;
    color: $light-gray-3;
    width: auto;

    @include media-breakpoint-up(sm) {
      opacity: 0;
    }
  }

  &:hover {
    .drag-handle {
      opacity: 1;
    }
  }
}

.card-sort-list .sort-item .drag-handle {
  top: $spacer-4;
  left: -$spacer-2;
  transform: translateX(-100%);
  opacity: 1;
}
