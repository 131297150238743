@use "../variables" as variables;

@mixin benefitsCheckList() {
  list-style: none;
  margin: variables.$spacer-5 0;
  padding-left: 0;

  li {
    padding-left: 1.25rem + variables.$spacer-3;
    background: url("/assets/list-check.svg") no-repeat 0 3px;
    margin-bottom: variables.$spacer-3;
  }
}
