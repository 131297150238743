.splide {
  .splide__arrows {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    display: none;
    justify-content: space-between;
    pointer-events: none;
    padding: 0 $spacer-6;

    @include media-breakpoint-up(xl) {
      display: flex;
      padding: 0 $spacer-8;
    }
    @include media-breakpoint-up(xxl) {
      padding: 0 $spacer-9;
    }

    .splide__arrow {
      background: $violet-gray;
      border: none;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      padding: 0 10px;
      color: $white;
      pointer-events: auto;
    }
  }

  div.splide__pagination {
    margin-top: $spacer-3;
    margin-bottom: $spacer-5;
    pointer-events: auto;

    li {
      list-style: none;
      margin: 0 6px;

      button {
        width: 10px;
        height: 10px;
        border: 0;
        border-radius: 100%;
        padding: 0;
        background: $white;
        opacity: 0.1;

        &.is-active {
          background: $secondary;
          opacity: 1;
        }
      }
    }
  }
}
