@import "universaljob-shared-styles/src/variables";
@import "variables/bootstrap-variables";
@import "variables/custom-variables";

$cvBaseWidth: 60.75rem; // 972px;
$page-padding: $spacer-8;

// toggle bar
$toggle-width: 3rem;
$toggle-bar-padding: $spacer-1;
$toggle-dot-size: 1.25rem;
$toggle-bar-height: $toggle-dot-size + $toggle-bar-padding * 2;
$toggle-bar-border-width: 0.0625rem; // 1px;
$toggle-bar-border: $toggle-bar-border-width solid $light-gray-2;

$pdf-gray-line-color: #c8c8c8;
