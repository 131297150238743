@mixin reset-button-style() {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;

  color: inherit;
  font-weight: inherit;
  background-color: transparent;
  border: none;
  text-align: left;
  padding: 0;
  width: 100%;

  &:active {
    box-shadow: none;
  }

  &:focus {
    box-shadow: none;
  }
}
