@import "../variables/bootstrap-variables";
@import "../mixins/media-steps";

.inner-container {
  width: 100%;
  max-width: 1120px;
}

.container {
  padding-left: $spacer-3;
  padding-right: $spacer-3;
  @include media-sm-special-spacing() {
    padding-left: $spacer-5;
    padding-right: $spacer-5;
  }
}

.container-stretch-mobile {
  @include nav-mobile() {
    padding: 0;
  }
}

.smaller-padded-container {
  @include media-breakpoint-up(xxl) {
    padding-left: $spacer-8 - ($grid-gutter-width * 0.5);
    padding-right: $spacer-8 - ($grid-gutter-width * 0.5);
  }
  @include media-breakpoint-up(xxxl) {
    padding-left: $spacer-9 - ($grid-gutter-width * 0.5);
    padding-right: $spacer-9 - ($grid-gutter-width * 0.5);
  }
}

.smaller-padded-container-left {
  @include media-breakpoint-up(xxl) {
    padding-left: $spacer-8 - ($grid-gutter-width * 0.5);
  }
  @include media-breakpoint-up(xxxl) {
    padding-left: $spacer-9 - ($grid-gutter-width * 0.5);
  }
}

.smaller-padded-container-right {
  @include media-breakpoint-up(xxl) {
    padding-right: $spacer-8 - ($grid-gutter-width * 0.5);
  }
  @include media-breakpoint-up(xxxl) {
    padding-right: $spacer-9 - ($grid-gutter-width * 0.5);
  }
}

@include media-breakpoint-down(md) {
  .mobile-wide-container {
    padding-left: 0;
    padding-right: 0;
  }
}
