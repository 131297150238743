$violet-gray: #4c4963;
$violet-gray-rgba: rgba(76, 73, 99, 0.6);
$violet-bright: rgba(147, 136, 231, 0.6);

$violet-bright-shadow: $light-gray-1; // #fafbfc
$violet-light-shadow: $light-gray-2; // #eae9f2

$white-transparent-1: rgba(255, 255, 255, 0.1);
$white-transparent-2: rgba(255, 255, 255, 0.2);
$white-transparent-3: rgba(255, 255, 255, 0.24);

$input-bg: #fff;
$form-select-bg: #fff;
