// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.
// Raleway
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&display=swap");
// Barlow
@import url("https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,200;0,300;0,500;1,200;1,300;1,500&display=swap");
// Roboto
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap");
// Nunito Sans
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,600;1,200;1,300;1,600&display=swap");
// Lora
@import url("https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,600;1,400;1,600&display=swap");

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$lebenslauf-generator-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$lebenslauf-generator-accent: mat.m2-define-palette(
  mat.$m2-pink-palette,
  A200,
  A100,
  A400
);

// The warn palette is optional (defaults to red).
$lebenslauf-generator-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$lebenslauf-generator-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $lebenslauf-generator-primary,
      accent: $lebenslauf-generator-accent,
      warn: $lebenslauf-generator-warn,
    ),
  )
);

@import "src/scss/vendor/croppie";

@import "bootstrap/scss/functions";
@import "src/scss/variables";
@import "bootstrap/scss/bootstrap";

@import "src/scss/mixins";
@import "src/scss/quill";
@import "src/scss/atoms";
@import "src/scss/template-styles";

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}

body {
  margin: 0;
}
