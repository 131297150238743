@import "src/scss/variables/bootstrap-variables";

@mixin header-height-media-up() {
  @media screen and (min-height: 600px) {
    @content;
  }
}

@mixin media-mobile() {
  @include media-breakpoint-down(sm) {
    @content;
  }
}

@mixin media-desktop() {
  @include media-breakpoint-up(sm) {
    @content;
  }
}

@mixin media-sm-special-spacing {
  @media only screen and (min-width: 360px) and (max-width: map-get($grid-breakpoints, lg)) {
    @content;
  }
}

@mixin nav-desktop {
  @include media-breakpoint-up(lg) {
    @content;
  }
}

@mixin nav-mobile {
  @include media-breakpoint-down(lg) {
    @content;
  }
}

@mixin media-modal-desktop() {
  @include media-breakpoint-up(md) {
    @content;
  }
}

@mixin media-modal-mobile() {
  @include media-breakpoint-down(md) {
    @content;
  }
}

@mixin media-custom-select-mobile() {
  @include media-modal-mobile() {
    @content;
  }
}

@mixin media-custom-select-desktop() {
  @include media-modal-desktop() {
    @content;
  }
}
