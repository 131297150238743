$negative-spacing: 0.75rem; // 12px;
$hover-border-width: 0.125rem; // 2px;
$hover-border-transparent: $hover-border-width solid transparent;
$hover-border: $hover-border-width solid $secondary;
$hover-border-radius: 0.5rem; // 8px;
$white-border-overlay-width: 0.375rem; // 6px;
$white-border-overlay-radius: 0.5rem; // 8px;

.edit-hover-container,
app-edit-hover-overlay {
  position: relative;

  &.section-link {
    cursor: pointer;
  }

  &:hover {
    // required to overlap next section with bottom controls
    z-index: 2;
  }

  &.has-click-action {
    cursor: pointer;
  }

  .edit-hover-content,
  .non-hover-area {
    position: relative;
    z-index: 1;
  }

  .hover-area {
    & > .edit-hover-overlay {
      z-index: 2;

      opacity: 0;
      transition: 0.2s opacity;
    }

    &:hover,
    &.highlighted {
      & > .edit-hover-overlay {
        background: $white;
        opacity: 1;
        pointer-events: all;
        z-index: 5;
      }

      & > .edit-hover-content,
      & + .non-hover-area {
        z-index: 6;
      }
    }

    &:hover {
      & > .edit-hover-overlay {
        z-index: 7;
        border-radius: $hover-border-radius 0 $hover-border-radius
          $hover-border-radius;

        & > .bottom-controls {
          display: block;
        }

        & > .top-controls-wrapper {
          opacity: 1;
        }
      }

      & > .edit-hover-content,
      & + .non-hover-area {
        z-index: 8;
      }
    }

    &.highlighted {
      & > .edit-hover-overlay {
        z-index: 5;
      }

      & > .edit-hover-content,
      & + .non-hover-area {
        z-index: 6;
      }
    }
  }

  .edit-hover-overlay {
    pointer-events: none;
    position: absolute;
    top: -$negative-spacing;
    left: -$negative-spacing;
    right: -$negative-spacing;
    bottom: -$negative-spacing;
    z-index: 2;
    border: $hover-border;

    box-shadow: 0 0 0 $white-border-overlay-width #fff;
    border-radius: $hover-border-radius;

    .sidebar-icon {
      position: absolute;
      top: $spacer-1;
      right: -$spacer-1;
      transform: translateX(100%);
    }
  }

  .bottom-controls {
    position: absolute;
    bottom: 0;
    left: -$hover-border-width;
    right: -$hover-border-width;
    transform: translateY(calc(100% - #{$hover-border-radius * 2}));
    border: $hover-border;
    border-width: 0 $hover-border-width $hover-border-width $hover-border-width;
    border-radius: 0 0 $hover-border-radius $hover-border-radius;
    padding-top: $hover-border-radius * 2;
    background: white;
    display: none;

    .edit-hover-fab-control {
      width: auto;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%) translateY(65%);
      z-index: 2;
    }

    .hover-overlay-bottom-control + .hover-overlay-bottom-control {
      margin-left: $spacer-3;
    }
  }

  .top-controls-wrapper {
    position: absolute;
    top: 0;
    left: 60%; // -$hover-border-width;
    right: -$hover-border-width;
    margin-top: -$hover-border-width;
    transform: translateY(-100%);
    display: flex;
    justify-content: flex-end;
    opacity: 0;
    transition: 0.1s opacity;
  }

  .top-controls {
    background-color: $secondary;
    padding: 0 0.25rem;

    border-radius: $hover-border-radius $hover-border-radius 0 0;
    // equal box shadow on 3 sides, not using border because of a pink pixel fragment below the white border on the right side
    box-shadow: -#{$white-border-overlay-width} -#{$white-border-overlay-width}
        0 #fff,
      #{$white-border-overlay-width} -#{$white-border-overlay-width} 0 #fff,
      #{$white-border-overlay-width} 0 0 #fff,
      -#{$white-border-overlay-width} 0 0 #fff;

    display: flex;
    color: white;

    & > * {
      padding: 0.25rem;
      transition: 0.2s background-color;

      &[disabled] {
        cursor: not-allowed;
        opacity: 0.6;
      }

      &:hover {
        background-color: rgba(255, 255, 255, 0.15);
      }
    }
  }
}

.hover-overlay-bottom-control {
  @include text-xs();

  padding: $spacer-3;
  color: $secondary;
  line-height: 1;
  display: flex;
  align-items: center;
  cursor: pointer;

  > * + * {
    margin-left: $spacer-2;
  }
}
