@import "src/scss/variables";
@import "src/scss/mixins";

$main-text-color: #222222;
$hint-text-color: #a7a7a7;

app-simple-template-html-preview {
  .page-numbers {
    bottom: 50px;
    right: $page-padding;
  }

  hr {
    background-color: #e8e8e8;
    opacity: 1;
    color: transparent;
  }

  .a4-page {
    padding: $page-padding;
  }
}
