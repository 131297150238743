@use "sass:math";

.ratio-a4 {
  width: 100%;
  padding-bottom: math.div(297, 210) * 100%;
  position: relative;
  margin-bottom: $spacer-3;
  &:last-child {
    margin-bottom: 0;
  }

  .a4-page {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background-color: $white;
    border-radius: $border-radius;

    display: flex;
    flex-direction: column;

    // used for debug rectangles
    & > * {
      position: relative;
    }
  }
}
