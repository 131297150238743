.animate-appear {
  animation: appear 0.5s ease-in-out;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
