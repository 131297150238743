@import "src/scss/variables";
@import "src/scss/mixins";

$main-text-color: #222222;
$hint-text-color: #a7a7a7;
$page-padding-left: 54px;
$dotSize: 8px;
$timeline-line-left: calc($page-padding-left + $dotSize / 2);
app-creative-template-html-preview {
  .page-numbers-creative {
    left: $page-padding-left;
    bottom: 54px;
    width: 200px;
    text-align: center;
  }
  .section-heading-small,
  .entry-text {
    text-align: center;
  }
  .main-sub-heading,
  .entry-meta-info {
    text-transform: uppercase;
  }

  .main-heading {
    flex-direction: column;
  }

  .timeline-dot {
    border-radius: 50%;
    position: absolute;
    top: 9px;
    left: -$timeline-line-left;
    transform: translateX(-50%);
    width: $dotSize;
    height: $dotSize;
  }
}
