@use "src/scss/variables";

$form-gutter-width: $spacer-3;

.form-row {
  margin-left: -$spacer-3 * 0.5;
  margin-right: -$spacer-3 * 0.5;

  .form-col {
    padding-left: $spacer-3 * 0.5;
    padding-right: $spacer-3 * 0.5;
    flex-grow: 1;

    // define same width for equal grow
    width: 100%;
  }
}

.form-control-highlighted {
  border-color: $secondary;
}

.content-editable-uppercase {
  text-transform: uppercase;

  &:focus {
    text-transform: none;
  }
}

@mixin contentEditableFocus() {
  outline: 1px solid rgba($violet-bright, 0.5);
  outline-offset: 0;
}

[contenteditable],
.preview-non-editable-content {
  position: relative;
  z-index: 0;
  outline: 1px solid transparent;

  outline-offset: -4px;
  transition: 0.1s outline-offset, 0.1s outline;

  &:focus,
  &:hover,
  &.highlight-content-editable {
    @include contentEditableFocus();
  }

  & > * {
    z-index: 1;
  }

  // fixes next linked field being above the current one
  &:hover {
    z-index: 1;
  }
}

.highlight-content-editable [contenteditable],
.highlight-content-editable .ql-snow [contenteditable] {
  @include contentEditableFocus();
}

quill-editor:not(.preview-snow-quill) {
  [contenteditable] {
    margin: 0;
    border-width: 0;
    outline: none;

    &:hover,
    &:focus {
      outline: none;
      border-width: 0;
    }
  }
}

.no-validation-icon {
  &.is-valid,
  &.is-invalid {
    background: none;
  }
}
