@import "src/scss/variables";
@import "src/scss/mixins";

$main-text-color: #222222;
$hint-text-color: #a7a7a7;

$page-padding-top: 80px;
$page-padding-right: 70px;
$page-padding-bottom: 70px;
$page-padding-left: 80px;

$header-padding: 54px;
app-elegant-template-html-preview {
  .page-numbers {
    bottom: 50px;
    right: $page-padding-right;
  }

  .a4-page {
    padding: $page-padding-top $page-padding-right $page-padding-bottom
      $page-padding-left;

    .decoration-top-bar {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: 10px;
      border-radius: $border-radius $border-radius 0 0;
    }
  }

  .page-header {
    margin: -#{$page-padding-top} -#{$page-padding-right} 0 -#{$page-padding-left};
    padding: $header-padding;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;

    &:not(.has-image) {
      padding-left: 80px;
    }
  }

  .render-section {
    position: relative;

    .section-decoration {
      position: absolute;
      left: -#{$page-padding-left};
      top: 8px;
      width: 60px;
      height: 2px;
    }
  }

  .page-header-columns {
    display: flex;

    .page-header-image-column {
      padding-right: 54px;
    }

    .page-header-main-column {
      flex-grow: 1;
    }

    .page-header-contact-column {
      width: 211px + 40px;
    }
  }
}
