.ql-container {
  .ql-editor {
    line-height: inherit;
  }

  .ql-editor ol > li[data-list="ordered"] {
    list-style-type: decimal;
  }

  .ql-editor ol > li {
    list-style-type: decimal;
  }

  .ql-editor ol > li[data-list="bullet"] {
    list-style-type: disc;
  }

  .ql-editor ul > li::before {
    content: none;
  }

  .ql-editor ul li {
    list-style-type: inherit;
  }

  .ql-editor li > .ql-ui:before {
    content: none;
  }

  .ql-editor ol,
  .ql-editor ul {
    margin-bottom: 0;
  }

  .ql-editor ol,
  .ql-editor ul,
  .ql-editor ol li,
  .ql-editor ul li {
    padding-left: 0;
  }

  .ql-editor ol li:before {
    content: "";
  }

  .ql-editor ol li.ql-indent-1 {
    counter-increment: list-1;
  }

  .ql-editor ol li.ql-indent-1:before {
    content: none;
  }
}
