$track-height: 0.5rem;
$track-border-radius: 0.5rem;
$thumb-height: 1.25rem;
$thumb-width: 1.25rem;
$thumb-border-radius: 50%;
$thumb-color: $secondary;
$thumb-border: 2px solid #fff;
$thumb-focus-border: $thumb-border; // 4px solid #fff;
$track-color: $secondary;

/********** Range Input Styles **********/
/*Range Reset*/
// cr-slider is for croppie
.custom-range-slider,
.cr-slider {
  display: block;
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  width: 100%;
  height: $thumb-height;

  &:focus {
    outline: none;
  }

  &::-webkit-slider-runnable-track {
    background-color: $track-color;
    border-radius: $track-border-radius;
    height: $track-height;
  }

  &::-webkit-slider-thumb {
    box-sizing: content-box;
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    margin-top: $track-height/2; /* Centers thumb on the track */
    transform: translateY(-50%);
    border-radius: $thumb-border-radius;
    border: $thumb-border;
    transition: 0.2s border-width;

    /*custom styles*/
    background-color: $thumb-color;
    height: $thumb-height;
    width: $thumb-width;
  }

  &::-moz-range-track {
    background-color: $track-color;
    border-radius: $track-border-radius;
    height: $track-height;
  }

  &::-moz-range-thumb {
    box-sizing: content-box;
    border: none; /*Removes extra border that FF applies*/
    border-radius: $thumb-border-radius;
    border: $thumb-border;
    transition: 0.2s border-width;

    /*custom styles*/
    background-color: $thumb-color;
    height: $thumb-height;
    width: $thumb-width;
  }

  &:focus::-webkit-slider-thumb {
    border: $thumb-focus-border;
    outline: none;
    outline-offset: 0;
  }

  &:focus::-moz-range-thumb {
    border: $thumb-focus-border;
    outline: none;
    outline-offset: 0;
  }
}
