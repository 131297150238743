$cv-edit-header-height: 4.625rem;
$cv-edit-header-height-lg: 6.375rem;
$scroll-margin-gap: $spacer-7;
.content-container {
  .cv-edit-header {
    min-height: $cv-edit-header-height;
  }

  & * {
    scroll-margin: ($cv-edit-header-height + $scroll-margin-gap) 0 0;
  }

  @include media-breakpoint-up(lg) {
    .cv-edit-header {
      min-height: $cv-edit-header-height-lg;
    }

    & * {
      scroll-margin: ($cv-edit-header-height-lg + $scroll-margin-gap) 0 0;
    }
  }
}
