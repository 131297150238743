button {
  color: $text-black;
}

.btn {
  hyphens: none;
  font-size: 1rem;
}

.btn-secondary {
  @include elevation-3();
}

.btn-white {
  border: 1px solid $violet-light-shadow;
  background-color: $white;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.condensed-space-button {
  padding: $spacer-2 $spacer-2;
  width: 100%;
  display: flex;
  justify-content: center;
}

.button-hidden {
  opacity: 0;
  pointer-events: none;
}

.custom-button-group {
  display: flex;
  @include elevation-2();

  > .btn {
    @include border-radius(0);
  }

  > .btn:first-child {
    @include border-start-radius($btn-border-radius);
  }

  > .btn:last-child {
    @include border-end-radius($btn-border-radius);
  }
}

.btn-secondary:focus {
  background-color: $secondary;
  border-color: $secondary;
}

.btn-primary:focus {
  background-color: $primary;
  border-color: $primary;
}

.btn-secondary:active {
  background-color: shade-color(
    tint-color($secondary, $btn-hover-bg-tint-amount),
    $btn-hover-bg-shade-amount
  );
  border-color: shade-color(
    tint-color($secondary, $btn-hover-bg-tint-amount),
    $btn-hover-bg-shade-amount
  );
}

.btn-primary:active {
  background-color: shade-color(
    tint-color($primary, $btn-hover-bg-tint-amount),
    $btn-hover-bg-shade-amount
  );
  border-color: shade-color(
    tint-color($primary, $btn-hover-bg-tint-amount),
    $btn-hover-bg-shade-amount
  );
}

.icon-button {
  display: flex;
  align-items: center;
  justify-content: center;

  .icon-button-icon {
    margin-right: $spacer-2;
  }
}

.reset-default-style {
  all: unset;
}

a.reset-default-style {
  cursor: pointer;

  &:hover {
    all: unset;
    cursor: pointer;
  }
}

.reset-button-style {
  @include reset-button-style();
}

.icon-fab-button {
  display: flex;
  align-items: center;
  border-radius: 1000px;
  padding: $spacer-2 $spacer-5;
}

.clickable-text {
  cursor: pointer;
}
