@import "template-styles/simple-template";
@import "template-styles/elegant-template";
@import "template-styles/modern-template";
@import "template-styles/classic-template";
@import "template-styles/creative-template";
.page-numbers {
  position: absolute;
}

.heading-decoration {
  flex-grow: 1;
  height: 1px;
  background-color: $pdf-gray-line-color;
  margin-left: 15px;
}
