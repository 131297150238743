@import "src/scss/variables";
@import "src/scss/mixins";

$main-text-color: #222222;
$hint-text-color: #a7a7a7;

app-modern-template-html-preview {
  .page-numbers {
    bottom: 54px;
    right: 54px;
  }
}
