@import "src/scss/variables";
@import "src/scss/mixins";

$main-text-color: #222222;
$hint-text-color: #a7a7a7;

$page-padding-top: 74px;
$page-padding-right: 54px;
$page-padding-bottom: 72px;
$page-padding-left: 54px;

$header-padding: 74px 54px 0px 114px;
$mainSectionMarginBottom: 54px;
$dotSize: 7px;
$dotMarginTop: 4px;
$dotMarginBottom: 4px;
$dotMargin: $dotMarginTop + $dotMarginBottom;
$leftItemWidth: 180px;
$leftItemWidthWithScale: calc(var(--scale) * $leftItemWidth);
$leftItemWidthDiff: calc($leftItemWidth - $leftItemWidthWithScale);
$verticalLineLeft: calc($leftItemWidthWithScale + 54px + 43px);
$verticalLineTop: calc(21px * var(--scale));
$header-margin-bottom: calc(64px * var(--scale));
app-classic-template-html-preview {
  --scale: 1;
  --full-page-height: 0;
  --header-height: 0;

  .page-numbers {
    bottom: 50px;
    right: $page-padding-right;
  }

  .a4-page {
    padding: $page-padding-top $page-padding-right $page-padding-bottom
      $page-padding-left;

    .decoration-top-bar {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: 10px;
      border-radius: $border-radius $border-radius 0 0;
    }

    .vertical-line {
      position: absolute;
      width: 1px;
      height: calc(100%);
      background-color: #e8e8e8;
      left: $verticalLineLeft;
      top: 0;
    }

    .vertical-line.firstPage {
      height: calc(
        var(--full-page-height) - var(--header-height) - $header-margin-bottom +
          $page-padding-bottom - $verticalLineTop
      );
      top: calc(
        $verticalLineTop + var(--header-height) + $header-margin-bottom +
          $page-padding-top
      );
    }
  }

  .page-header {
    margin: -#{$page-padding-top} -#{$page-padding-right} 0 -#{$page-padding-left};
    padding: $header-padding;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;

    &:not(:has(.page-header-columns > .page-header-image-column)) {
      padding-left: calc((223px - $leftItemWidthDiff) + $page-padding-left);
    }
  }

  .render-section {
    position: relative;
  }

  .main-column {
    .section-decoration {
      position: absolute;
      left: -#{$page-padding-left};
      top: 8px;
      width: 60px;
      height: 2px;
    }

    .columns {
      display: flex;
      flex-grow: 1;
      justify-content: stretch;

      .left-item {
        min-width: $leftItemWidthWithScale;
        max-width: $leftItemWidthWithScale;
        text-align: end;
        margin-right: 40px;
      }

      .right-item {
        flex-grow: 1;
        text-align: start;
        margin-left: 40px;
      }

      .timeline-container {
        position: relative;
        width: 7px;
        .section-dot {
          margin-top: 10px;
          border-radius: 50%;
          min-width: 7px;
          min-height: 7px;
          box-shadow: 0 0 0 4px #fff;
          z-index: 1;
        }
      }

      .section-heading {
        position: relative;
      }
    }
  }

  .page-header-columns {
    display: flex;

    .page-header-image-column {
      padding-right: 40px;
    }

    .page-header-main-column {
      flex-grow: 1;
    }

    .page-header-contact-column {
      width: 211px + 40px;
    }
  }
}
