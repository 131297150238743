.modal-actions-row {
  display: flex;
  flex-grow: 1;

  & > * {
    width: 0;
    flex-grow: 1;
  }

  & > * + * {
    margin-left: $spacer-2;
  }

  & + .modal-actions-row {
    margin-top: 12px;
  }
}
