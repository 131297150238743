@import "~quill/dist/quill.snow.css";
@import "reset-quill";

$quill-preview-snow-padding: 0.375rem; // .25rem;
$quill-border-radius: 0.25rem;

quill-editor:not(.preview-snow-quill) {
  background-color: $white;

  .ql-editor {
    padding: $spacer-3;
    min-height: (1.4rem * 4) + ($spacer-3 * 2);
    overflow: hidden;
    font-size: $input-font-size;

    &.ql-blank::before {
      top: $spacer-3;
      left: $spacer-3;
      border: none;
    }
  }
}

.ql-container {
  display: block;
}

.preview-snow-quill {
  position: relative;
  display: block;

  .ql-toolbar {
    position: absolute;
    top: -$quill-preview-snow-padding;
    left: -$quill-preview-snow-padding;
    right: -$quill-preview-snow-padding;
    opacity: 0;
    pointer-events: none;
    transform: translateY(-100%);
    background-color: white;

    transition: 0.2s opacity;
  }

  .ql-container,
  &.ql-container {
    border: none;
    position: relative;
    z-index: 2;

    .ql-editor {
      padding: 0;
      min-height: 0;

      &.ql-blank::before {
        top: 0;
        left: 0;
      }
    }
  }

  &:before {
    content: "";
    position: absolute;
    top: -$quill-preview-snow-padding;
    left: -$quill-preview-snow-padding;
    right: -$quill-preview-snow-padding;
    bottom: -$quill-preview-snow-padding;
    border-width: 0 1px 1px 1px;
    border-style: solid;
    border-color: transparent;
    border-radius: 0 0 $quill-border-radius $quill-border-radius;

    transition: 0.2s border-color;
  }

  &:focus-within {
    .ql-toolbar {
      opacity: 0.6;
      pointer-events: all;

      &:hover {
        opacity: 1;
      }
    }

    &:before {
      border-color: #ccc;
    }
  }
}

.form-control-highlighted {
  .ql-toolbar {
    border-color: $secondary;
  }

  .ql-container {
    border-color: $secondary;
  }
}

.ql-toolbar {
  display: flex;
  flex-wrap: wrap;
  background-color: $white;
  border-radius: $quill-border-radius $quill-border-radius 0 0;

  .ql-formats {
    display: flex;
    margin: 0;

    &:first-child {
      margin-left: 0;
    }
  }
}

.ql-container {
  font-family: inherit;
  font-size: inherit;
  border-radius: 0 0 $quill-border-radius $quill-border-radius;

  .ql-clipboard {
    position: absolute;
  }
}
